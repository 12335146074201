define(['layout', 'wixappsLayout/specificComponents/wixappsLayoutUtils'], function (/** layout.layout */ layout, wixappsLayoutUtils) {
    'use strict';

    const {childImageComponent, patchNodePhoto, measurePhoto} = layout.specificComponents.wPhotoLayout;
    const {patchNodeImage} = layout.specificComponents.imageLayout;

    function patchNodePhotoWixApps(id, patchers, measureMap, structureInfo, siteData) {
        patchNodePhoto(id, patchers, measureMap, structureInfo, siteData);
        const image = window.document.getElementById(`${id}`).querySelector('*[data-image-info]');

        if (image) {
            const imageId = image.getAttribute('id');
            const imageInfo = JSON.parse(image.dataset.imageInfo);
            imageInfo.imageData.displayMode = imageInfo.displayMode;
            patchNodeImage(imageId, patchers, measureMap, siteData, imageInfo.imageData, null, imageInfo.alignType);
        }
    }

    wixappsLayoutUtils.registerRequestToMeasureDom('wysiwyg.viewer.components.WPhoto');
    wixappsLayoutUtils.registerRequestToMeasureChildren('wysiwyg.viewer.components.WPhoto', [childImageComponent, ['link']]);
    wixappsLayoutUtils.registerPatcher('wysiwyg.viewer.components.WPhoto', patchNodePhotoWixApps);
    wixappsLayoutUtils.registerCustomMeasure('wysiwyg.viewer.components.WPhoto', measurePhoto);

    return {};
});
